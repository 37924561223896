import { DefaultOptionType } from 'antd/es/select';
import { useEffect, useMemo, useState } from 'react';
import { initialProductTableParams } from 'src/constants/products';
import { ITableParams } from 'src/types/generic';
import {
  TMappedProductOfferRecord,
  getAssignedProductsColumnSetting,
  getMappedProductOffersData,
} from 'src/utils/map-assigned-product-table';
import useProducts from './use-products';
import useDebouncer from './use-debouncer';
import { ColumnFilterItem } from 'antd/es/table/interface';
import useCompanies from './use-companies';
import { Typography } from 'antd';
import styles from 'src/styles/actions.module.scss';

type TUseMySuppliersProductsParams = {
  isSuppliers: boolean;
  debouncedSearch: string;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
};

const useMySuppliersProducts = ({
  debouncedSearch,
  currencies,
  uoms,
  isSuppliers,
}: TUseMySuppliersProductsParams) => {
  const { getSuppliersProducts } = useProducts();
  const { getSuppliers } = useCompanies();

  const [filterSearch, setFilterSearch] = useState('');
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);
  const [debouncedFilterSearch, setDebouncedFilterSearch] = useState('');
  const [filters, setFilters] = useState<ColumnFilterItem[]>([]);
  const [supplierData, setSupplierData] = useState<TMappedProductOfferRecord[]>(
    []
  );
  const [suppliersTotal, setSuppliersTotal] = useState<number>(0);
  const [suppliersLoading, setSuppliersLoading] = useState<boolean>(false);
  const [supplierTableParams, setSupplierTableParams] = useState<ITableParams>(
    initialProductTableParams
  );

  const sortedFilters = useMemo(() => {
    const selectedValues = supplierTableParams.filters?.['supplier.name'];

    const res = filters.slice();

    if (!selectedValues) {
      return res;
    }
    const sortedFilters = res.sort((a, b) => {
      const aValue = a.value as React.Key;
      const bValue = b.value as React.Key;

      const isASelected = selectedValues.includes(aValue);
      const isBSelected = selectedValues.includes(bValue);
      return Number(isBSelected) - Number(isASelected);
    });

    return sortedFilters;
  }, [supplierTableParams.filters, filters]);

  const suppliersColumns = useMemo(() => {
    const res = getAssignedProductsColumnSetting({
      actionsHidden: true,
      currencies,
      uoms,
      search: debouncedSearch,
      withSupplier: true,
      filters: sortedFilters,
      setFilterSearch,
      searchLoading: filtersLoading,
      isFilterSearch: !!debouncedFilterSearch,
    });
    return res;
  }, [
    supplierTableParams,
    debouncedSearch,
    uoms,
    currencies,
    sortedFilters,
    filtersLoading,
  ]);

  useDebouncer(setDebouncedFilterSearch, filterSearch);

  const fetchSupplierProducts = async () => {
    setSuppliersLoading(true);
    const result = await getSuppliersProducts({
      ...supplierTableParams,
      search: debouncedSearch.length >= 3 ? debouncedSearch : '',
    });
    if (result.result?.data.data) {
      setSupplierData(getMappedProductOffersData(result.result.data.data));
      setSuppliersTotal(result.result.data.totalCount);
    }
    setSuppliersLoading(false);
  };

  useEffect(() => {
    const fetchSuppliers = async () => {
      if (!!debouncedFilterSearch && debouncedFilterSearch.length < 3) {
        return;
      }
      setFiltersLoading(true);
      const res = await getSuppliers({
        pagination: {
          current: 1,
          pageSize: 10,
        },
        sortField: 'name',
        sortOrder: 'ascend',
        search: debouncedFilterSearch,
      });

      if (res.result?.data) {
        const filters = res.result.data.data.map((supplier) => ({
          text: (
            <div className={styles.filter}>
              <Typography.Text className={styles.filterTitle}>
                {supplier.name}
              </Typography.Text>
              <Typography.Text
                className={styles.filterDescription}
              >{`${supplier.gln || '-'} | ${supplier.supplierPersonalId || '-'}`}</Typography.Text>
            </div>
          ),
          value: supplier.id,
          description: supplier.name,
        }));

        setFilters(filters);
      }
      setFiltersLoading(false);
    };
    fetchSuppliers();
  }, [debouncedFilterSearch]);

  useEffect(() => {
    if (isSuppliers) {
      fetchSupplierProducts();
    }
  }, [supplierTableParams]);

  return {
    supplierData,
    suppliersLoading,
    suppliersTotal,
    suppliersColumns,
    fetchSupplierProducts,
    setSupplierTableParams,
    setSupplierData,
    supplierTableParams,
  };
};

export default useMySuppliersProducts;
