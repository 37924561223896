import { LoadingOutlined } from '@ant-design/icons';
import { Button, Checkbox, Empty, Input, Spin } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import clsx from 'clsx';
import { useState } from 'react';
import { IconCancel } from 'src/components/icons/cancel/IconCancel';
import { IconSearch } from 'src/components/icons/search/IconSearch';
import { NO_RESULTS, NO_SUPPLIERS_ADDED } from 'src/constants/validation';
import styles from './TableFilters.module.scss';

type TTableFilterProps = FilterDropdownProps & {
  setSearch?: (value: string) => void;
  loading?: boolean;
  isFilterSearch: boolean;
};

const TableFilters = ({
  selectedKeys,
  setSelectedKeys,
  clearFilters,
  confirm,
  setSearch,
  filters,
  loading,
  isFilterSearch,
}: TTableFilterProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Input
          allowClear={
            !loading && {
              clearIcon: <IconCancel height="16" width="16" />,
            }
          }
          prefix={<IconSearch width="16" height="16" className={styles.icon} />}
          suffix={
            loading ? (
              <Spin size="small" indicator={<LoadingOutlined spin />} />
            ) : undefined
          }
          className={styles.search}
          placeholder={'GLN, supplier name, id'}
          onChange={(event) => {
            setSearch?.(event.target.value);
          }}
        />
      </div>
      <div className={styles.body}>
        {!!filters?.length ? (
          filters.map((filter, i) => {
            const value = filter.value as React.Key;
            const isSelected = selectedKeys.includes(value);
            return (
              <div
                className={clsx(styles.row, {
                  [styles.match]: isFilterSearch && i == 0,
                  [styles.active]: isSelected,
                })}
                key={value}
                onClick={() => {
                  if (isSelected) {
                    setSelectedKeys(
                      selectedKeys.filter((key) => key !== value)
                    );
                  } else {
                    setSelectedKeys([...selectedKeys, value]);
                  }
                }}
              >
                <Checkbox checked={isSelected} />
                {filter.text}
              </div>
            );
          })
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={isFilterSearch ? NO_RESULTS : NO_SUPPLIERS_ADDED}
          />
        )}
      </div>
      <div className={styles.footer}>
        <Button
          size="small"
          type="text"
          disabled={!selectedKeys.length}
          onClick={clearFilters}
        >
          Reset
        </Button>
        <Button
          size="small"
          type="primary"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default TableFilters;
