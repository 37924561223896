import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Select,
  Tooltip,
  Typography,
} from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { IconCheck } from 'src/components/icons/check/IconCheck';
import { IconClose } from 'src/components/icons/close/IconClose';
import { IconDate } from 'src/components/icons/date/IconDate';
import { IconEdit } from 'src/components/icons/edit/IconEdit';
import { IconSort, SortOrder } from 'src/components/icons/sort/IconSort';
import { IconUnlink } from 'src/components/icons/unlink/IconUnlink';
import Cell from 'src/components/table/cell/Cell';
import { BROADCASTNAME } from 'src/constants/broadcast';
import {
  ActionType,
  ModalContextActions,
  TModalContextStateAction,
} from 'src/contexts/modal-context';
import { TEditAssignedCustomer } from 'src/hooks/use-products';
import styles from 'src/styles/actions.module.scss';
import { IMappedProductOffer, IProductOffer } from 'src/types/general';
import { ArrayElement } from 'src/types/generic';
import { formatDate } from './date-formatter';
import { TMappedProductOfferRecord } from './map-assigned-product-table';
import { formatNumber } from './number-formatter';

const properties: (keyof IMappedProductOffer | 'actions')[] = [
  'customer.gln',
  'customer.name',
  'productOfferPrice.price',
  'productOfferPrice.currency',
  'quantity',
  'unitOfMeasurement',
  'productOfferPrice.startDate',
  'productOfferPrice.expirationDate',
  'createdAt',
  'customer.industry',
  'actions',
];

type TGetMappedProductDetailsDataParams = {
  modalDispatch: React.Dispatch<TModalContextStateAction>;
  setEditRow: (index: number, record: TMappedProductOfferRecord) => void;
  setLoading: (value: boolean) => void;
  unsetEditRow: (index: number) => void;
  edit: TEditAssignedCustomer;
  productId: string;
  actionsHidden: boolean;
  isEditing: boolean;
  currencies: DefaultOptionType[];
  uoms: DefaultOptionType[];
  form: FormInstance;
  showErrorMessage: (text: string) => void;
  showSuccessMessage: (text: string) => void;
};

export const getMappedProductDetailsData = (
  data: IProductOffer[]
): TMappedProductOfferRecord[] => {
  return data.map((row) => {
    const res: TMappedProductOfferRecord = {
      key: row.id,
      'customer.gln': row.customer?.gln,
      'customer.name': row.customer?.name,
      'productOfferPrice.price': row.productOfferPrice.price,
      'productOfferPrice.currency': row.productOfferPrice.currency,
      quantity: row.quantity,
      unitOfMeasurement: row.unitOfMeasurementId,
      'productOfferPrice.startDate': row.productOfferPrice.startDate,
      'productOfferPrice.expirationDate': row.productOfferPrice.expirationDate,
      createdAt: row.createdAt,
      'customer.industry': row.customer?.industry,
      actions: {
        canDelete: row.canDelete,
        canEdit: row.canEdit,
      },
      isEditing: false,
    };
    return res;
  });
};

export const getProductDetailsColumnSetting = function ({
  modalDispatch,
  setEditRow,
  unsetEditRow,
  edit,
  productId,
  actionsHidden,
  setLoading,
  currencies,
  uoms,
  isEditing,
  form,
  showSuccessMessage,
  showErrorMessage,
}: TGetMappedProductDetailsDataParams) {
  const result = properties.map(
    (property): ArrayElement<ColumnsType<TMappedProductOfferRecord>> => {
      switch (property) {
        case 'customer.gln':
          return {
            title: 'GLN',
            width: 128,
            key: property,
            fixed: 'left',
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'customer.name':
          return {
            title: 'Customer name',
            width: 200,
            key: property,
            ellipsis: true,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'productOfferPrice.price':
          return {
            title: 'List price',
            width: 120,
            align: 'end',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <InputNumber<number>
                      className={styles.numeric}
                      size="small"
                      min={0.01}
                      controls={false}
                      formatter={(value) => {
                        if (!value && value !== 0) {
                          return '';
                        }
                        if (value <= 0) {
                          return formatNumber(0.01);
                        }
                        return formatNumber(value);
                      }}
                    />
                  </Form.Item>
                );
              }
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };

        case 'productOfferPrice.currency':
          return {
            title: 'Currency',
            width: 96,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <Select size="small" options={currencies} />
                  </Form.Item>
                );
              }
              return (
                <Cell isValue={!!value}>
                  {currencies.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'quantity':
          return {
            title: 'Assigned Qty',
            width: 128,
            align: 'end',
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <InputNumber<number>
                      className={styles.numeric}
                      size="small"
                      min={0.01}
                      controls={false}
                      formatter={(value) => {
                        if (!value && value !== 0) {
                          return '';
                        }
                        if (value <= 0) {
                          return formatNumber(0.01);
                        }
                        return formatNumber(value);
                      }}
                    />
                  </Form.Item>
                );
              }
              return <Cell isValue={!!value}>{formatNumber(value)}</Cell>;
            },
          };
        case 'unitOfMeasurement':
          return {
            title: 'Assigned UoM',
            width: 134,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <Select size="small" options={uoms} />
                  </Form.Item>
                );
              }
              return (
                <Cell isValue={!!value}>
                  {uoms.find((el) => el.value === value)?.label}
                </Cell>
              );
            },
          };
        case 'productOfferPrice.startDate':
          return {
            title: 'Price effective',
            width: 130,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item className={styles.noMargin} name={property}>
                    <DatePicker
                      size="small"
                      format={'DD/MM/YYYY'}
                      suffixIcon={
                        <IconDate
                          width="16"
                          height="16"
                          color={styles.iconCalendar}
                        />
                      }
                    />
                  </Form.Item>
                );
              }
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'productOfferPrice.expirationDate':
          return {
            title: 'Price expiration',
            width: 138,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value, record) => {
              if (record.isEditing) {
                return (
                  <Form.Item
                    className={styles.noMargin}
                    name={property}
                    initialValue={value ? dayjs(value) : value}
                  >
                    <DatePicker
                      size="small"
                      format={'DD/MM/YYYY'}
                      suffixIcon={
                        <IconDate
                          width="16"
                          height="16"
                          color={styles.iconCalendar}
                        />
                      }
                    />
                  </Form.Item>
                );
              }
              return (
                <Cell isValue={!!value}>
                  <Typography.Text
                    className={clsx(styles.noMargin, {
                      [styles.expired]: new Date(value) < new Date(),
                    })}
                  >
                    {formatDate(value)}
                  </Typography.Text>
                </Cell>
              );
            },
          };
        case 'createdAt':
          return {
            title: 'Assigned on',
            width: 128,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            defaultSortOrder: 'descend',
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{formatDate(value)}</Cell>;
            },
          };
        case 'customer.industry':
          return {
            title: 'Industry',
            width: 155,
            ellipsis: true,
            key: property,
            dataIndex: property,
            sorter: true,
            showSorterTooltip: false,
            sortIcon: (props) => (
              <IconSort
                width="16"
                height="16"
                order={props.sortOrder as SortOrder}
              />
            ),
            render: (value) => {
              return <Cell isValue={!!value}>{value}</Cell>;
            },
          };
        case 'actions':
          if (actionsHidden) {
            return { hidden: true };
          }

          return {
            key: property,
            width: 60,
            dataIndex: property,
            fixed: 'right',
            render: (value, record, index) => {
              return record.isEditing ? (
                <div className={styles.component}>
                  <Tooltip title="Save">
                    <Button
                      size="small"
                      type="text"
                      onClick={async (e) => {
                        e.stopPropagation();
                        setLoading(true);

                        const data = form.getFieldsValue();

                        const res = await edit(productId, record.key, {
                          price: data['productOfferPrice.price'] || 0,
                          currency: data['productOfferPrice.price']
                            ? data['productOfferPrice.currency']
                            : 0,
                          unitOfMeasurementId: data.quantity
                            ? data.unitOfMeasurement
                            : null,
                          quantity: data.quantity || 0,
                          expirationDate:
                            data['productOfferPrice.expirationDate'],
                          startDate: data['productOfferPrice.startDate'],
                        });
                        if (!res.errors) {
                          showSuccessMessage('Changes saved');
                          const broadcast = new BroadcastChannel(BROADCASTNAME);
                          broadcast.postMessage('refresh');
                          broadcast.close();
                        } else {
                          showErrorMessage('Changes not saved');
                        }
                      }}
                      icon={
                        <IconCheck
                          color={styles.iconFillActive}
                          height="16"
                          width="16"
                        />
                      }
                    />
                  </Tooltip>
                  <Tooltip title="Cancel">
                    <Button
                      size="small"
                      type="text"
                      onClick={(e) => {
                        e.stopPropagation();
                        unsetEditRow(index);
                      }}
                      icon={
                        <IconClose
                          color={styles.iconFillActive}
                          height="16"
                          width="16"
                        />
                      }
                    />
                  </Tooltip>
                </div>
              ) : (
                <div
                  className={clsx(styles.component, {
                    [styles.end]: !value.canEdit,
                  })}
                >
                  {value.canEdit && (
                    <Tooltip title="Edit">
                      <Button
                        disabled={isEditing}
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditRow(index, record);
                        }}
                        icon={
                          <IconEdit
                            color={styles.iconFill}
                            height="16"
                            width="16"
                          />
                        }
                      />
                    </Tooltip>
                  )}
                  {value.canDelete && (
                    <Tooltip title="Decouple">
                      <Button
                        size="small"
                        type="text"
                        onClick={(e) => {
                          e.stopPropagation();
                          modalDispatch({
                            type: ModalContextActions.SHOW_MODAL,
                            value: {
                              title: 'Decouple customer from product',
                              description: `Customer ${record['customer.name']} will be removed from the list.`,
                              actionText: 'Decouple',
                              action: ActionType.DECOUPLE_PRODUCTS_CUSTOMER,
                              dataId: productId,
                              subDataId: record.key,
                              dataName: record['customer.name'] || '-',
                            },
                          });
                        }}
                        icon={
                          <IconUnlink
                            color={styles.iconFill}
                            height="16"
                            width="16"
                          />
                        }
                      />
                    </Tooltip>
                  )}
                </div>
              );
            },
          };

        default:
          return { hidden: true };
      }
    }
  );
  return result;
};
